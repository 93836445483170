import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/shop',
  name: 'shop',
  meta: { title: '商城' },
  redirect: '/shop/lists',
  component: Main,
  children: [
    {
      path: '/shop/lists',
      name: 'lists',
      meta: {
        title: '商城管理',
        parentPath: '/shop',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/lists',
      children: [
        {
          path: '/shop/lists',
          name: 'shop_lists',
          meta: {
            title: '商城列表',
            parentPath: '/shop',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists.vue')
        },
        {
          path: '/shop/lists_detail',
          name: 'lists_detail',
          meta: {
            hidden: true,
            title: '商城信息',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists_edit.vue')
        },
        {
          path: '/shop/modify_account',
          name: 'modify_account',
          meta: {
            hidden: true,
            title: '修改超级管理员账号',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/modify_account.vue')
        },
        {
          path: '/shop/modelManage',
          name: 'modelManage',
          meta: {
            hidden: true,
            title: '模型管理',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/modelManage.vue')
        },
        {
          path: "/shop/model_edit",
          name: "shop_model_edit",
          meta: {
            hidden: true,
            title: "编辑模型",
            parentPath: "/setting",
            permission: ["view"],
          },
          component: () => import("@/views/shop/shop/edit.vue"),
        },
      ]
    },
    {
      path: '/shop/set_meal',
      name: 'set_meal',
      meta: {
        title: '套餐管理',
        parentPath: '/shop',
        icon: 'icon-taocanguanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/set_meal',
      children: [
        {
          path: '/shop/set_meal',
          name: 'set_meal',
          meta: {
            title: '套餐列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/lists.vue')
        },
        {
          path: '/shop/set_meal_edit',
          name: 'set_meal_edit',
          meta: {
            hidden: true,
            title: '商城套餐',
            parentPath: '/shop',
            prevPath: '/shop/set_meal',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/edit.vue')
        }
      ]
    },
    {
      path: '/shop/model',
      name: 'set_meal',
      meta: {
        title: '模型管理',
        parentPath: '/shop',
        icon: 'icon-taocanguanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/model_list',
      children: [
        {
          path: '/shop/model_list',
          name: 'model_list',
          meta: {
            title: '模型列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/model/modelList.vue')
        },
        {
          path: '/shop/model_list_edit',
          name: 'model_list_edit',
          meta: {
            hidden: true,
            title: '编辑模型',
            parentPath: '/shop',
            prevPath: '/shop/model_list',
            permission: ['view']
          },
          component: () => import('@/views/shop/model/edit.vue')
        }
      ]
    }
  ]
}]

export default routes
