
import { Component, Vue } from "vue-property-decorator";
import { apiCrontabEdit, apiDetail, apiModelEdit } from "@/api/setting";
import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
  components: {
    MaterialSelect,
  },
})
export default class TaskEdit extends Vue {
  /** S Data **/
  mode: string = PageMode["ADD"]; // 当前页面: add-添加角色 edit-编辑角色

  // 表
  lists: Array<object> = [];

  // 表单数据
  form: any = {
    title: "", //	是	string	名称
    en_title: "", //	是	string	命令
    image: "", //	是	string	运行规则
    is_show: 1, //	是	integer	状态
    price: "", //	否	string	备注
    sort: "", //	否	string	参数
  };

  // 表单校验
  rules: object = {
    title: [{ required: true, message: "请输入名称", trigger: "blur" }],
    price: [{ required: true, message: "请输入价格", trigger: "blur" }],
  };

  /** E Data **/

  /** S Methods **/
  // 提交表单
  onSubmit(formName: string) {
    const refs = this.$refs[formName] as HTMLFormElement;
    refs.validate((valid: boolean): any => {
      if (!valid) return;
      // 请求发送
      //   switch (this.mode) {
      //     case PageMode["ADD"]:
      //       return this.handleNoticeAdd();
      //     case PageMode["EDIT"]:
      //       return this.handleNoticeEdit();
      //   }
      this.handleNoticeEdit();
    });
  }

  // 编辑帮助文章
  async handleNoticeEdit(): Promise<void> {
    await apiModelEdit(this.form);
    setTimeout(() => this.$router.go(-1), 500);
  }

  // 初始化表单数据
  async initFormDataForNoticeEdit(): Promise<void> {
    const res = await apiDetail({
      id: this.form.id,
    });
    Object.keys(res).map((item) => {
      this.$set(this.form, item, res[item]);
    });
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query;
    if (query.mode) this.mode = query.mode;

    if (this.mode === PageMode["EDIT"]) {
      this.form.id = query.id * 1;
      this.initFormDataForNoticeEdit();
    }
  }

  /** E Life Cycle **/
}
