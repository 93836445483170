
import { apiModelLists, apiChangeStatus,apiChangeIsable } from "@/api/shop";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
  },
})
export default class ShopList extends Vue {
  /** S Data **/

  apiModelLists = apiModelLists;

  pager: RequestPaging = new RequestPaging();

  searchObj: Object = {
    title: "",
  };
  /** E Data **/

  /** S Methods **/

  // 获取模型列表
  getShopLists(): void {
    this.pager.request({
      callback: apiModelLists,
      params: {
        ...this.searchObj,
      },
    });
    console.log(11111, this.pager);
  }

  // 修改启用禁用状态
  async shopSwitchIsableEdit(status: any, mx_id: number): Promise<void> {
    await apiChangeIsable({
      sid: this.searchObj.sid,
      mx_id: mx_id,
      isable: status,
    });
    this.getShopLists();
  }

  // 修改上下架状态
  async shopSwitchStatusEdit(status: any, mx_id: number): Promise<void> {
    await apiChangeStatus({
      sid: this.searchObj.sid,
      mx_id: mx_id,
      is_show: status,
    });
    this.getShopLists();
  }

  // 删除商城
  async onShopDelete(row: any): Promise<void> {
    await apiShopDelete({ id: row.id });
    this.getShopLists();
  }

  // 重置搜索
  reset(): void {
    // Object.keys(this.searchObj).map((key) => {
    //   this.$set(this.searchObj, key, "");
    // });
    this.searchObj.title = "";
    this.getShopLists();
  }

  // 去编辑
  toShopEdit(mx_id: number | any): void {
    this.$router.push({
      path: "/shop/model_edit",
      query: {
        mx_id: mx_id as any,
        sid: this.searchObj.sid as any,
        mode: PageMode["EDIT"],
      },
    });
  }

  // 修改超级管理员
  toSuperAdminUpdate(id: number | any): void {
    this.$router.push({
      path: "/shop/modify_account",
      query: {
        id: id,
      },
    });
  }

  // 去商家后台
  toShop(url: any, status: number) {
    if (status == 0) return this.$message.error("该商城暂未营业中");

    if (url.indexOf("https://") === 0) window.open(url);
    else if (url.indexOf("http://") === 0) window.open(url);
    else window.open("http://" + url);
  }

  /** E Methods **/

  /** S ods **/
  created() {
    const query: any = this.$route.query;
    this.searchObj.sid = query.id * 1;
    this.getShopLists();
  }
}
