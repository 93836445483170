import request from '@/plugins/axios'

/** S 商城列表 **/
// 商城列表
export const apiShopLists = (params: any) => request.get('/shop.shop/lists', {params})

// 添加商城
export const apiShopAdd = (params: any) => request.post('/shop.shop/add', params)

// 商城详情
export const apiShopDetail = (params: any) => request.get('/shop.shop/detail', {params})

// 编辑商城
export const apiShopEdit = (params: any) => request.post('/shop.shop/edit', params)

// 删除商城
export const apiShopDelete = (params: any) => request.post('/shop.shop/delete', params)

// 修改超级管理员
export const apiShopChangeSuperAdmin = (params: any) => request.post('/shop.shop/changeSuperAdmin', params)

// 修改商城状态
export const apiShopSwitchStatus = (params: any) => request.post('/shop.shop/switchStatus', params)

// 历史套餐
export const apisetMealLogLists = (params: any) => request.get('/shop.shop/setMealLogLists', {params})

// 修改商城备注
export const apiChangeRemark = (params: any) => request.post('/shop.shop/changeRemark', params)
/** E 商城列表 **/

/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) => request.get('/shop.set_meal/lists', {params})

// 添加套餐
export const apiSetMealAdd = (params: any) => request.post('/shop.set_meal/add', params)

// 套餐详情
export const apiSetMealDetail = (params: any) => request.get('/shop.set_meal/detail', {params})

// 编辑套餐
export const apiSetMealEdit = (params: any) => request.post('/shop.set_meal/edit', params)

// 删除套餐
export const apiSetMealDelete = (params: any) => request.post('/shop.set_meal/del', params)

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) => request.post('/shop.set_meal/status', params)

/** E 套餐列表 **/

/** S 模型管理 **/
//商户模型列表
export const apiModelLists = (params: any) =>
  request.get("/settings.Goodscopysid/lists", { params });
// 商户模型详情
export const apiDetail = (params: any) =>
  request.get("/settings.Goodscopysid/detail", { params });
// 商户更改模型上下架
export const apiChangeStatus = (params: any) =>
  request.post("/settings.Goodscopysid/is_show", params);
// 商户更改模型启用/禁用
export const apiChangeIsable = (params: any) =>
  request.post("/settings.Goodscopysid/isable", params);
// 商户编辑模型
export const apiModelEdit = (params: any) =>
  request.post("/settings.Goodscopysid/edit", params);
/** E 模型管理 **/